(function()
{
 	var app = angular.module('share', []);

 	app.run(["$rootScope", function($rootScope)
 	{
 		jQuery(".share-button").click(function(e)
		{
			e.preventDefault();

			jQuery("#share-dialog").appendTo("#overlay").removeClass("hidden");
			jQuery("#overlay").css(
            {
            	left: "auto",
            	right: 0
            }).animate(
			{
				height: "toggle",
				width: "toggle"
			}, 300);
		});

 		$rootScope.closeShare = function()
 		{
 			jQuery("#overlay").animate(
			{
				height: "toggle",
				width: "toggle"
			}, 300, function()
			{
				jQuery("#share-dialog").appendTo("body").addClass("hidden");
			});
 		};

		// jQuery(".close-share-dialog").click(function(e)
		// {
		// 	e.preventDefault();
		// 	jQuery( "#share-dialog" ).dialog("close");
		// });
	}]);
})();
